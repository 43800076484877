<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="getColor()"
        :target="target || null"
        :href="href || null"
        :text="getBtnType() == 'textBtn'"
        :disabled="disabled"
        :block="block"
        :class="[dynamicClass, ' white--text ']"
        @click="onBtnClick"
        v-if="getBtnType() == 'textBtn' || getBtnType() == 'regularBtn'"
        v-bind="attrs"
        v-on="toolTipText && on"
      >
        <v-icon :size="iconSize || 20" left v-if="icon" class="mr-2">{{
          icon
        }}</v-icon>
        {{ text }}
      </v-btn>
      <v-icon
        :size="iconSize || 20"
        v-if="getBtnType() == 'iconBtn'"
        :color="getColor()"
        @click="onBtnClick"
        :disabled="disabled"
        :target="target || null"
        :href="href || null"
        :class="dynamicClass"
        v-bind="attrs"
        v-on="toolTipText && on"
        >{{ icon }}</v-icon
      >
    </template>
    <span>{{ toolTipText }}</span>
  </v-tooltip>
</template>

<script>
import { includes } from "lodash";
export default {
  props: [
    "type",
    "icon",
    "text",
    "iconSize",
    "to",
    "disabled",
    "href",
    "target",
    "block",
    "color",
    "toolTipText",
    "dynamicClass",
  ],
  //type => primary, primaryText, primaryIcon, warning, warningText, warningIcon
  methods: {
    getColor() {
      // also works if there will be case-sensitive errors
      const typeTitle = this.type ? this.type.toLowerCase() : "";
      const warningBtns = ["warning", "warningtext", "warningicon"];
      if (this.color) {
        return this.color;
      } else {
        if (includes(warningBtns, typeTitle)) {
          return "error";
        } else {
          return "primary";
        }
      }
    },
    getBtnType() {
      const typeTitle = this.type ? this.type.toLowerCase() : "";
      let btnType = includes(typeTitle, "icon")
        ? "iconBtn"
        : includes(typeTitle, "text")
        ? "textBtn"
        : "regularBtn";
      return btnType;
    },
    onBtnClick() {
      if (this.to) {
        this.$router.push({ path: this.to });
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped>
.v-btn ::v-deep .v-btn__content {
  white-space: normal;
  flex: auto;
}

.v-btn {
  height: 100% !important;
  min-height: 40px;
  max-width: 90%;
}
</style>